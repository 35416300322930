import moment from "moment";

export class Clock {
  constructor({ animate, preview, update, animateOff, triggerAnimation }) {
    this.onAir = false;
    this.time = 0;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = (scene, data) => {
      console.log(data);
      update(scene, data);
    };
    this.triggerAnimation = triggerAnimation;
    this.name = "CLOCK";
    this.scene = "Clock";
    this.data = [];
    this.home_score = 0;
    this.away_score = 0;
    this.period_number = 0;
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [
      { name: "Home Team Name", value: { text: options.game.home_team.code } },
      { name: "Away Team Name", value: { text: options.game.away_team.code } },
      { name: "Game Clock", value: { text: options.clock } },
    ];
    this.sendPreview(this.scene, 25, this.data, "IN");
    this.time++;
  }
  animate({ options }) {
    this.onAir = true;
    this.added_time_on_air = false;
    this.data = [];
    let score = this.getScore(options.game);

    this.data.push({
      name: "Home Team Kit BG Colour",
      value: {
        colour:
          options.game.home_team?.variables?.[
            options.game?.home_team_kit + "_kit_bg"
          ] || "",
      },
    });
    this.data.push({
      name: "Home Team Kit Text Colour",
      value: {
        colour:
          options.game.home_team?.variables?.[
            options.game?.home_team_kit + "_kit_text"
          ] || "",
      },
    });
    this.data.push({
      name: "Away Team Kit BG Colour",
      value: {
        colour:
          options.game.away_team?.variables?.[
            options.game?.away_team_kit + "_kit_bg"
          ] || "",
      },
    });
    this.data.push({
      name: "Away Team Kit Text Colour",
      value: {
        colour:
          options.game.away_team?.variables?.[
            options.game?.away_team_kit + "_kit_text"
          ] || "",
      },
    });
    this.data.push({
      name: "Home Team Code",
      value: { text: options.game.home_team.code || "" },
    });
    this.data.push({
      name: "Away Team Code",
      value: { text: options.game.away_team.code || "" },
    });
    this.data.push({
      name: "Home Score",
      value: { text: score.home_score || 0 },
    });
    this.data.push({
      name: "Away Score",
      value: { text: score.away_score || 0 },
    });
    this.data.push({
      name: "Home Goals",
      value: {
        text:
          options.game?.home_scorers?.filter((g) => g.type === "goal").length ||
          0,
      },
    });
    this.data.push({
      name: "Away Goals",
      value: {
        text:
          options.game?.away_scorers?.filter((g) => g.type === "goal").length ||
          0,
      },
    });
    this.data.push({
      name: "Home Points",
      value: {
        text:
          options.game?.home_scorers
            ?.filter((g) => g.type === "point")
            ?.length?.toString()
            ?.padStart(2, "0") || "00",
      },
    });
    this.data.push({
      name: "Away Points",
      value: {
        text:
          options.game?.away_scorers
            ?.filter((g) => g.type === "point")
            ?.length?.toString()
            ?.padStart(2, "0") || "00",
      },
    });
    this.data.push({
      name: "Home Team Badge",
      value: {
        image: "${user}" + options.game?.home_team?.variables?.badge,
      },
    });

    this.data.push({
      name: "Away Team Badge",
      value: {
        image: "${user}" + options.game?.away_team?.variables?.badge,
      },
    });

    this.home_score = options.game.home_scorers?.length || 0;
    this.away_score = options.game.away_scorers?.length || 0;
    let clock = options.period.kick_off;
    this.kick_off = options.period.kick_off;
    this.period_number = options.period.number;
    this.data.push({
      name: "Additional Time",
      value: {
        text: options.added_time || "",
      },
    });
    this.data.push({
      name: "Period",
      value: { text: options.period.number },
    });

    let latestPause =
      options.period.pause &&
      options.period.pause[options.period.pause.length - 1];
    if (latestPause && latestPause.end) {
      latestPause = null;
    }

    this.data.push({
      name: "Game Clock",
      value: {
        action: latestPause ? "reset" : "start",
        time: this.getSeconds({ period: options.period, game: options.game }),
      },
    });

    let home_reds = options.game?.lineup?.home?.reduce((prev, curr) => {
      return (prev +=
        curr.red_card ||
        curr.dbl_yellow_card ||
        (curr.yellow_card && curr.black_card)
          ? 1
          : 0);
    }, 0);

    let away_reds = options.game?.lineup?.away?.reduce((prev, curr) => {
      return (prev +=
        curr.red_card ||
        curr.dbl_yellow_card ||
        (curr.yellow_card && curr.black_card)
          ? 1
          : 0);
    }, 0);

    let home_blacks = options.game?.lineup?.home?.reduce((prev, curr) => {
      return (prev +=
        curr.black_card &&
        (curr.yellow_card == false ||
          curr.yellow_card == null ||
          curr.yellow_card == undefined)
          ? 1
          : 0);
    }, 0);

    let away_blacks = options.game?.lineup?.away?.reduce((prev, curr) => {
      return (prev +=
        curr.black_card &&
        (curr.yellow_card == false ||
          curr.yellow_card == null ||
          curr.yellow_card == undefined)
          ? 1
          : 0);
    }, 0);

    if (
      !options.game?.lineup?.away ||
      options.game?.lineup?.away?.length === 0
    ) {
      try {
        const home_reds_local = JSON.parse(
          localStorage.getItem("home_reds")
        )?.find((r) => r.game === options?.game?._id);
        home_reds = 0;
        if (home_reds_local?.one) {
          home_reds += 1;
        }
        if (home_reds_local?.two) {
          home_reds += 1;
        }
        if (home_reds_local?.three) {
          home_reds += 1;
        }
      } catch (err) {}
    }

    if (
      !options.game?.lineup?.away ||
      options.game?.lineup?.away?.length === 0
    ) {
      try {
        const away_reds_local = JSON.parse(
          localStorage.getItem("away_reds")
        )?.find((r) => r.game === options?.game?._id);
        away_reds = 0;
        if (away_reds_local?.one) {
          away_reds += 1;
        }
        if (away_reds_local?.two) {
          away_reds += 1;
        }
        if (away_reds_local?.three) {
          away_reds += 1;
        }
      } catch (err) {}
    }

    this.home_reds_total = home_reds;
    this.away_reds_total = away_reds;
    this.home_blacks_total = home_blacks;
    this.away_blacks_total = away_blacks;

    this.data.push({
      name: "Home Red Cards",
      value: {
        text: home_reds || 0,
      },
    });
    this.data.push({
      name: "Away Red Cards",
      value: {
        text: away_reds || 0,
      },
    });

    this.data.push({
      name: "Home Black Cards",
      value: {
        text: home_blacks || 0,
      },
    });
    this.data.push({
      name: "Away Black Cards",
      value: {
        text: away_blacks || 0,
      },
    });

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.added_time_on_air = false;
    this.sendAnimateOff(this.scene, []);
  }
  update({ options }) {
    let score = this.getScore(options.game);
    this.data = [];

    if (options.type === "PAUSE") {
      this.pause({ options });
    } else if (options.type === "RESUME") {
      this.resume({ options });
    } else if (options.type === "ACC_SCORE") {
      this.data.push({
        name: "Show Acc Score",
        value: {
          text: options.show,
        },
      });
      this.sendUpdate(this.scene, this.data);
    } else if (options.type === "ADDED_TIME") {
      this.data.push({
        name: "Additional Time",
        value: {
          text: options.added_time,
        },
      });
      this.sendUpdate(this.scene, this.data);
    } else {
      let home_reds = options.game?.lineup?.home?.reduce((prev, curr) => {
        return (prev +=
          curr.red_card ||
          curr.dbl_yellow_card ||
          (curr.yellow_card && curr.black_card)
            ? 1
            : 0);
      }, 0);

      let away_reds = options.game?.lineup?.away?.reduce((prev, curr) => {
        return (prev +=
          curr.red_card ||
          curr.dbl_yellow_card ||
          (curr.yellow_card && curr.black_card)
            ? 1
            : 0);
      }, 0);

      let home_blacks = options.game?.lineup?.home?.reduce((prev, curr) => {
        return (prev +=
          curr.black_card &&
          (curr.yellow_card == false ||
            curr.yellow_card == null ||
            curr.yellow_card == undefined)
            ? 1
            : 0);
      }, 0);

      let away_blacks = options.game?.lineup?.away?.reduce((prev, curr) => {
        return (prev +=
          curr.black_card &&
          (curr.yellow_card == false ||
            curr.yellow_card == null ||
            curr.yellow_card == undefined)
            ? 1
            : 0);
      }, 0);

      if (
        !options.game?.lineup?.away ||
        options.game?.lineup?.away?.length === 0
      ) {
        try {
          const home_reds_local = JSON.parse(
            localStorage.getItem("home_reds")
          )?.find((r) => r.game === options?.game?._id);
          home_reds = 0;
          if (home_reds_local?.one) {
            home_reds += 1;
          }
          if (home_reds_local?.two) {
            home_reds += 1;
          }
          if (home_reds_local?.three) {
            home_reds += 1;
          }
        } catch (err) {}
      }

      if (
        !options.game?.lineup?.away ||
        options.game?.lineup?.away?.length === 0
      ) {
        try {
          const away_reds_local = JSON.parse(
            localStorage.getItem("away_reds")
          )?.find((r) => r.game === options?.game?._id);
          away_reds = 0;
          if (away_reds_local?.one) {
            away_reds += 1;
          }
          if (away_reds_local?.two) {
            away_reds += 1;
          }
          if (away_reds_local?.three) {
            away_reds += 1;
          }
        } catch (err) {}
      }

      if (
        this.home_score !== (options.game.home_scorers?.length || 0) ||
        this.away_score !== (options.game.away_scorers?.length || 0) ||
        this.kick_off !== options.period.kick_off ||
        this.period_number !== options.period.number ||
        this.home_reds_total !== home_reds ||
        this.away_reds_total !== away_reds ||
        this.home_blacks_total !== home_blacks ||
        this.away_blacks_total !== away_blacks
      ) {
        debugger;
        this.data.push({
          name: "Home Red Cards",
          value: {
            text: home_reds || 0,
          },
        });
        this.data.push({
          name: "Away Red Cards",
          value: {
            text: away_reds || 0,
          },
        });
        this.data.push({
          name: "Home Black Cards",
          value: {
            text: home_blacks || 0,
          },
        });
        this.data.push({
          name: "Away Black Cards",
          value: {
            text: away_blacks || 0,
          },
        });
        this.data.push({
          name: "Home Score",
          value: { text: score.home_score || 0 },
        });
        this.data.push({
          name: "Away Score",
          value: { text: score.away_score || 0 },
        });
        this.data.push({
          name: "Home Goals",
          value: {
            text:
              options.game?.home_scorers?.filter((g) => g.type === "goal")
                .length || 0,
          },
        });
        this.data.push({
          name: "Away Goals",
          value: {
            text:
              options.game?.away_scorers?.filter((g) => g.type === "goal")
                .length || 0,
          },
        });
        this.data.push({
          name: "Home Points",
          value: {
            text:
              options.game?.home_scorers
                ?.filter((g) => g.type === "point")
                ?.length?.toString()
                ?.padStart(2, "0") || 0,
          },
        });
        this.data.push({
          name: "Away Points",
          value: {
            text:
              options.game?.away_scorers
                ?.filter((g) => g.type === "point")
                ?.length?.toString()
                ?.padStart(2, "0") || 0,
          },
        });
        this.kick_off = options.period.kick_off;

        let pauseTime = 0;

        options.period.pause &&
          options.period.pause.forEach((pause) => {
            if (pause.end) {
              pauseTime += pause.end - pause.start;
            }
          });
        let latestPause =
          options.period.pause &&
          options.period.pause[options.period.pause.length - 1];
        if (latestPause && latestPause.end) {
          latestPause = null;
        }

        if (!options.period.kick_off) {
          this.data.push({
            name: "Game Clock",
            value: {
              action: "reset",
              time: this.getSeconds({
                period: options.period,
                game: options.game,
              }),
            },
          });
        } else if (latestPause) {
          this.data.push({
            name: "Game Clock",
            value: {
              action: "reset",
              time: this.getSeconds({
                period: options.period,
                game: options.game,
              }),
            },
          });
        } else {
          this.data.push({
            name: "Game Clock",
            value: {
              action: options.period.kick_off ? "start" : "stop",
              time: this.getSeconds({
                period: options.period,
                game: options.game,
              }),
            },
          });
        }

        this.data.push({
          name: "Period",
          value: { text: options.period.number },
        });
        this.sendUpdate(this.scene, this.data);
      }
      this.home_reds_total = home_reds;
      this.away_reds_total = away_reds;
      this.home_blacks_total = home_blacks;
      this.away_blacks_total = away_blacks;
      this.home_score = options.game.home_scorers?.length || 0;
      this.away_score = options.game.away_scorers?.length || 0;
      this.period_number = options.period.number;
      this.kick_off = options.period.kick_off;
    }
  }
  pause({ options }) {
    let data = [];
    data.push({
      name: "Game Clock",
      value: {
        action: "reset",
        time: this.getSeconds({ period: options.period, game: options.game }),
      },
    });
    this.sendUpdate(this.scene, data);
  }
  resume({ options }) {
    let data = [];
    data.push({
      name: "Game Clock",
      value: {
        action: "start",
        time: this.getSeconds({ period: options.period, game: options.game }),
      },
    });
    this.sendUpdate(this.scene, data);
  }
  getScore(game) {
    let goal_points = 3;

    let home_score =
      (game?.home_scorers?.filter((g) => g.type === "goal").length *
        goal_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "point").length || 0);

    let away_score =
      (game?.away_scorers?.filter((g) => g.type === "goal").length *
        goal_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "point").length || 0);

    return {
      home_score,
      away_score,
    };
  }
  getSeconds({ period, game }) {
    if (period.kick_off) {
      let pauseTime = 0;

      period.pause &&
        period.pause.forEach((pause) => {
          if (pause.end) {
            pauseTime += pause.end - pause.start;
          }
        });
      let latestPause = period.pause && period.pause[period.pause.length - 1];
      if (latestPause && latestPause.end) {
        latestPause = null;
      }
      let time = moment.duration(
        ((latestPause && latestPause.start) || Date.now()) -
          period.kick_off -
          pauseTime
      );

      let minutes = time.minutes() + time.hours() * 60;

      if (period.number === 2) {
        minutes += game?.config?.half_duration || 45;
      } else if (period.number === 3) {
        minutes += game?.config?.half_duration || 45;
        minutes += game?.config?.half_duration || 45;
      } else if (period.number === 4) {
        minutes += 105;
      }
      let mins = minutes;
      let secs = time.seconds();
      let total_seconds = mins * 60 + secs;
      return total_seconds * 1000;
    }
    if (period.number === 1) {
      return 0;
    } else if (period.number === 2) {
      return (game?.config?.half_duration || 45) * 60 * 1000;
    } else if (period.number === 3) {
      return (game?.config?.half_duration || 45) * 2 * 60 * 1000;
    } else if (period.number === 4) {
      return (game?.config?.half_duration || 45) * 3 * 60 * 1000;
    }
    return 0;
  }
}
